<template>
    <div class="page-admin-site table-page__thead--sticky">
        <card title="Filter" bodyClass="overflow-visible" class="border-radius card-header--border-none">
            <div slot="tools" class="flex border-b--none">
                <button v-if="filters.organization_id" class="flex flex__align-center text-body" @click="resetFilter">
                    <svg-importer icon-name="icons/reset" class="mr-2" /> Reset
                </button>
            </div>

            <div class="flex flex__align-end flex__justify-between w-full">
                <modal-field label="Organization" class="relative w-1/3">
                    <multiselect
                        v-if="organizations"
                        v-model="filters.organization_id"
                        :options="organizations"
                        :multiple="false"
                        placeholder="Please select"
                        track-by="id"
                        label="name"
                    >
                        <span
                            slot="noResult"
                        >Oops! No elements found. Consider changing the search query.</span>
                    </multiselect>
                </modal-field>
                <router-link
                    :to="{ name: 'admin.sites.create' }"
                    class="inline-block"
                    :class="[routeIncludesAdmin ? 'btn-primary' : 'btn']"
                >
                    Add site
                </router-link>
            </div>
        </card>

        <card class="bg-transparent" bodyClass="p-0 -mx-6 mb-6">
            <v-table
                class="admin-table"
                :id="'sites'"
                :columns="columns"
                row-key="uuid"
                ref="sites"
                :endpoint="$apiUrl.sites.base"
                sort-key="created_at"
                :filter-params="filterParams"
                :item-size="49"
                query-params
                no-cache
                :loading="loadingSites"
                :on-rows-loaded="onRowsLoaded"
                @row-click="rowClicked"
            >
                <template slot="cell" slot-scope="{row, col}">
                    <span
                        v-if="col.key == 'users_count'"
                    >{{ row.users_count }} users</span>

                    <span class="flex flex__justify-end w-full text-right" v-else-if="col.key == 'edit'">
                        <button @click="editSite(row.id)">
                            <svg-importer icon-name="icons/edit" :key="`site_${row.id}_edit`" />
                        </button>

                        <button @click="removeSite(row.id)" class="ml-6" href="">
                            <svg-importer width="20" icon-name="icons/close" :key="`site_${row.id}_close`" />
                        </button>
                    </span>
                    <div v-else-if="col.key == 'organization-name'" :title="row['organization']['name']">{{ row['organization']['name'] }}</div>
                    <span v-else>{{ row[col.key] }}</span>
                </template>
            </v-table>
        </card>

        <delete-modal
            :show="showDeleteModal"
            @hide="closeDeleteModal"
            name="Site"
            :selectedObject="selectedSite"
            :organization="selectedOrganization"
            :organizations="organizations"
            @delete="initDelete"
            :sites="sites"
        ></delete-modal>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "SiteIndex",

        metaInfo() {
            return { title: "Sites" };
        },

        data: () => ({
            organizations: null,
            sites: null,
            newSite: null,
            showDeleteModal: false,
            selectedSite: null,
            selectedOrganization: null,
            showMoveUsers: false,
            rowsEmpty: false,
            deleteAction: "",
            filters: {
                organization_id: null
            },
            loadingSites: false,
            filterParams: {
                include: "users,organization"
            },
            columns: [
                {
                    title: "ID",
                    key: "id",
                    toggled: true,
                    sortable: true,
                    width: "w-24"
                },
                {
                    title: "Name",
                    key: "name",
                    toggled: true,
                    sortable: true,
                    width: "w-1/2"
                },
                {
                    title: "Organization",
                    key: "organization-name",
                    toggled: true,
                    sortable: false,
                    width: "w-1/2"
                },
                {
                    title: "Users",
                    key: "users_count",
                    toggled: true,
                    sortable: true,
                    width: "w-1/2"
                },
                {
                    title: "",
                    key: "edit",
                    toggled: true,
                    sortable: false,
                    width: "w-1/3"
                }
            ]
        }),

        created() {
            this.fetchOrganizations();
            this.fetchSites();
        },

        computed: {
            tableRows() {
                return this.$refs.sites.rows;
            },
            routeIncludesAdmin() {
                return this.$route.path.includes("admin") ? true : false;
            }
        },

        watch: {
            filters: {
                handler() {
                    this.setFilterParams();
                },
                deep: true
            }
        },
        methods: {
            editSite(id) {
                this.$router.push({
                    name: "admin.sites.edit",
                    params: {
                        id: id
                    }
                });
            },
            onRowsLoaded(rows) {
                if (rows.length) {
                    this.rowsEmpty = true;
                } else {
                    this.rowsEmpty = false;
                }
            },
            async fetchOrganizations() {
                const { data } = await axios.get(
                    this.$apiUrl.organizations.active + '?pageSize=1000&include=sites'
                );

                this.organizations = data.data;
            },
            async fetchSites() {
                const { data } = await axios.get(
                    this.$apiUrl.sites.base + "?pageSize=1000&include=users"
                );

                this.sites = data.data;
            },
            setFilterParams() {
                let keys = Object.keys(this.filters);

                let params = {
                    include: "organization"
                };

                keys.forEach(filter => {
                    if (this.filters[filter] && filter != "range") {
                        params["filter[" + filter + "]"] = this.filters[filter].id
                            ? this.filters[filter].id
                            : this.filters[filter];
                    }
                });
                this.filterParams = params;
            },
            resetFilter() {
                this.filters.organization_id = null;
            },
            removeSite(id) {
                this.showDeleteModal = true;
                let site;
                this.selectedSite = this.sites.find(site => site.id == id);
                this.selectedOrganization = this.organizations.find(
                    organization =>
                        organization.id == this.selectedSite.organization_id
                );
            },
            async moveUsers(deleteAction) {
                let params = {
                    newSite: deleteAction.newSite.id
                };
                try {
                    const { data } = await axios.post(
                        `${this.$apiUrl.sites.base}/` + this.selectedSite.id + "/move-users/",
                        params
                    );
                    this.$refs.sites.table.fetchRows();
                    this.closeDeleteModal();
                } catch (e) {
                    console.log(e, "error");
                }
            },
            initDelete(deleteAction) {
                if (deleteAction.command == "move") {
                    this.moveUsers(deleteAction);
                } else if (deleteAction.command == "delete") {
                    this.deleteSite();
                }
            },
            closeDeleteModal() {
                this.showDeleteModal = false;
                (this.selectedOrganization = null), (this.selectedSite = null);
                this.newOrganization = null;
                this.showMoveUsers = false;
            },
            async deleteSite() {
                try {
                    const { data } = await axios.delete(
                        `${this.$apiUrl.sites.base}/` + this.selectedSite.id
                    );
                    this.$refs.sites.table.fetchRows();
                    this.closeDeleteModal();
                } catch (e) {
                    console.log(e, "error");
                }
            },
            rowClicked() {}
        }
    };
</script>

<style lang="scss">
.page-admin-site {
    .table-wrapper {
        max-height: calc(100vh - 324px);
        overflow: auto;
    }
}
</style>
